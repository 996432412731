<template>
  <div class="MemolanguageFlytogetSignupView">
    <div class="affiliateIcon" />

    <div class="madeWithMemolife">
      <span>Made with</span>
      <svgicon
        class="svg-icon"
        icon="heart" />
      <span>memolife</span>
    </div>

    <div class="spacerTop" />

    <div class="box">
      <div
        class="title"
        v-html="$t('title')" />
      <div
        v-t="'subtitle'"
        class="subtitle" />
      <div class="languages">
        <div
          v-for="languageId of availableLanguages"
          :key="languageId"
          class="Language">
          <div
            class="Language__icon"
            :style="{ backgroundImage: `url(${getFlagImage(languageId)})` }" />
        </div>
      </div>
      <MemolanguageFacebookButton
        class="facebookButton"
        :label="$t('facebookButton')"
        @success="onFacebookSignupSuccess()" />

      <div
        class="emailSignup --global-clickable"
        @click="gotoSignup()">
        <span v-t="'emailButton'" />
      </div>
    </div>

    <div
      v-t="'alreadyMember'"
      class="signinButton --global-clickable"
      @click="gotoLogin()" />
  </div>
</template>

<translations>
  title: 'Learn Fast.<br> Remember Forever!'
  title_no: 'Lær hurtig.<br> Husk for alltid!'
  subtitle: 'Sign up to select your language'
  subtitle_no: 'Sign opp for å velge språk'

  facebookButton: 'Sign up with Facebook'
  facebookButton_no: 'Sign opp med Facebook'
  emailButton: 'or use e-mail'
  emailButton_no: 'eller bruk e-post'

  alreadyMember: 'Already a member?'
  alreadyMember_no: 'Allerede medlem?'
</translations>

<script>
import MemolanguageFacebookButton from '../components/MemolanguageFacebookButton';
const images = require.context('@/assets/images/', true, /\.(png|jpg)$/);

export default {
    components: { MemolanguageFacebookButton },
    computed: {
        availableLanguages() {
            return this.$store.getters['moduleApp/availableLanguagesFlytoget'];
        }
    },
    methods: {
        getFlagImage(languageId) {
            try {
                return images(`./flags/${languageId}.png`);
            } catch (err) {
                return null;
            }
        },
        onFacebookSignupSuccess() {
            this.$router.push({ name: 'MemolanguageFlytogetLanguagesView' });
        },
        gotoSignup() {
            this.$router.push({ name: 'AccountSignupView-basic', query: { next: '/memolanguage/flytoget/languages' } });
        },
        gotoLogin() {
            this.$router.push({ name: 'AccountLoginView', query: { next: '/memolanguage/flytoget/languages' } });
        }
    }
};
</script>

<style lang="scss" scoped>
$loadingFadeDuration: 0.3s;
$whiteColor: white;

.MemolanguageFlytogetSignupView {
    color: $whiteColor;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2em 2em;
    padding-top: 2em;
    padding-bottom: 0em;
    overflow-y: auto;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)),
        url('../aurelia/images/signup-bg-image.jpg');
    background-position: center top;
    background-size: cover;

    &.is-loading {
        pointer-events: none; // Disabling interaction
    }

    &.is-processing {
        pointer-events: none;
    }
}

.spacerTop1 {
    height: 1.5em;
    min-height: 1em;
}
.spacerTop {
    // height: 3em;
    min-height: 1em;
    flex: 1;
}

.affiliateIcon {
    $size: 4em;
    display: block;
    width: $size;
    height: $size;
    background-color: $memolanguage-flytogetOrange;

    background-image: url('../aurelia/images/flytoget-square.jpg');
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    flex-shrink: 0;

    margin-bottom: 1em;
}

.box {
    background-color: white;
    max-width: 20em;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    padding: 1em;
    padding-top: 1.5em;
    padding-bottom: 0;
}

.title {
    color: rgba(black, 0.8);
    font-size: 140%;
    font-weight: 600;
}

.subtitle {
    color: rgba(black, 0.6);
    margin-top: 1em;
}

.languages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1.5em;
}

.Language {
    margin: 0.5em 0.5em;
}

.Language__icon {
    $size: 2.2em;
    width: $size;
    height: $size;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
}

.madeWithMemolife {
    font-size: 90%;
    display: flex;
    align-items: center;
    color: rgba(white, 0.7);
    font-weight: 300;
    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: rgba(white, 0.7);
        margin: 0 0.3em;
    }
}

.facebookButton {
    min-width: 20em;
}
.emailSignup {
    padding: 1em;
    color: rgba(black, 0.7);
    span {
        font-size: 90%;
    }
}

.signinButton {
    padding: 1.5em 0;
    color: rgba(white, 0.8);
    font-weight: 300;
}
</style>
