<template>
  <div class="MemolanguageFacebookButton">
    <div
      class="button --global-clickable"
      @click="signup()">
      <div class="facebookIcon">
        <svgicon
          class="svg-icon"
          icon="facebook" />
      </div>
      {{ label }}
      <svgicon
        v-if="processing"
        class="loadwheel"
        icon="loadwheel" />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        label: String
    },
    data() {
        return {
            processing: false
        };
    },
    methods: {
        async signup() {
            this.processing = true;
            try {
                await this.$store.dispatch('moduleAuth/authenticate', 'facebook');
            } catch (err) {
                return;
            } finally {
                this.processing = false;
            }
            this.$emit('success');
        }
    }
};
</script>

<style lang="scss" scoped>
$greenColor: green;

.MemolanguageFacebookButton {
    width: 100%;
    flex-shrink: 0;
    text-align: center;
}

.button {
    position: relative;
    background-color: $memolanguage-greenColor;
    border-radius: 2em;
    text-align: center;
    // padding: 1em;
    padding: 0 2em;
    height: 3.8em;
    font: inherit;
    color: rgba(white, 0.9);

    outline: none;

    // border: 1px solid rgba(white, 0.7);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.facebookIcon {
    $size: 2.2em;

    display: inline-flex;
    margin-right: 1em;
    justify-content: center;
    align-items: center;

    background-color: rgba(white, 0.9); //#3f5993;
    border-radius: 0.5em;
    width: $size;
    height: $size;

    .svg-icon {
        display: inline-block;
        $size: 1em;
        width: $size;
        height: $size;
        fill: $memolanguage-greenColor;
    }
}
.loadwheel {
    $size: 1.2rem;
    display: block;
    width: $size;
    height: $size;
    fill: rgba(white, 0.9);
    position: absolute;
    top: calc(50% - #{$size / 2});
    right: 1em;

    animation: rotate360 2s linear infinite;
}
@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
